import './navigation.scss';

class Navigation {
    constructor () {
        this.$navigation = document.querySelector('[data-nav="root"]');
        this.$navigationAccordions = document.querySelectorAll('[data-nav="accordion"]');
        this.$navToggles = [].slice.call(this.$navigation.querySelectorAll('[data-nav-toggle]'));
        this.$navClose = [].slice.call(this.$navigation.querySelectorAll('[data-nav="close"]'));
        this.$navMenu = this.$navigation.querySelector('[data-nav="menu"]');
        this.$mobileMenuToggle = this.$navigation.querySelector('[id="menu-toggle"]');
        this.$cartReminder = this.$navigation.querySelector('[id="cart-reminder"]');
        this.scrollPosition = 0;
        this.lastScrollPos = 0;
        this.scrollDistance = 350;
        this.menuOpen = false;
        this.wait = false;
        this.$headerNavAnchors = this.$navigation.querySelectorAll('.site-header__wrap a');
        this.cancelableTimeout = null;
        this.activeScrollNav = true;

        this.$navObj = {};

        this.$activeNav = null;
    }

    initialize () {
        const lotteryType = document.body.getAttribute('data-lottery-type');
        const documentType = document.body.getAttribute('data-document-type');

        if ((lotteryType === 'skl' || lotteryType === 'nkl') && documentType === 'details') {
            this.activeScrollNav = false;
        }

        this.$navToggles.forEach((toggle) => {
            const type = toggle.getAttribute('data-nav-toggle');
            this.$navObj[type] = {
                toggle: toggle,
                menu: this.$navigation.querySelector('[data-nav-menu="' + type + '"]')
            };
        });

        this.setEvents();
    }

    setEvents () {
        for (let accordionIndex = 0; accordionIndex < this.$navigationAccordions.length; accordionIndex++) {
            this.$navigationAccordions[accordionIndex].addEventListener('click', () => {
                const _this = this.$navigationAccordions[accordionIndex];
                const $container = _this.nextElementSibling;

                if (!_this.classList.contains('is--active')) {
                    _this.classList.add('is--active');

                    $container.style.maxHeight = $container.scrollHeight + 'px';
                } else {
                    _this.classList.remove('is--active');
                    $container.style.maxHeight = '';
                }
            });
        }

        this.$navToggles.forEach((toggle) => {
            toggle.addEventListener('mouseenter', () => {
                const type = toggle.getAttribute('data-nav-toggle');
                clearTimeout(this.cancelableTimeout);

                if (!this.$navObj[type].toggle.classList.contains('is--open')) {
                    let timeout = 0;
                    this.menuOpen = true;
                    this.bodyScrollLock('enable');

                    timeout = 500;
                    if (type === 'nkl') {
                        this.$navObj.skl.toggle.classList.remove('is--open');
                        this.$navObj.contact.toggle.classList.remove('is--open');
                    } else {
                        this.$navObj.nkl.toggle.classList.remove('is--open');
                    }

                    if (type === 'contact') {
                        this.$navObj.skl.toggle.classList.remove('is--open');
                        this.$navObj.nkl.toggle.classList.remove('is--open');
                    } else {
                        this.$navObj.contact.toggle.classList.remove('is--open');
                    }

                    if (!this.$navMenu.classList.contains('is--open')) {
                        this.$navMenu.classList.add('is--open');
                        this.$navObj[type].menu.classList.add('is--active');
                    } else {
                        this.$navMenu.classList.remove('is--open');
                    }
                    this.wait = true;

                    setTimeout(() => {
                        if (type === 'nkl') {
                            this.$navObj.skl.menu.classList.remove('is--active');
                            this.$navObj.contact.menu.classList.remove('is--active');
                        } else {
                            this.$navObj.nkl.menu.classList.remove('is--active');
                        }

                        if (type === 'contact') {
                            this.$navObj.skl.menu.classList.remove('is--active');
                            this.$navObj.nkl.menu.classList.remove('is--active');
                        } else {
                            this.$navObj.contact.menu.classList.remove('is--active');
                        }
                        this.$activeNav = null;
                        this.wait = false;
                    }, 500);

                    setTimeout(() => {
                        this.$navObj[type].menu.classList.add('is--active');
                        this.$navObj[type].toggle.classList.add('is--open');
                        this.$navMenu.classList.add('is--open');
                        this.$activeNav = type;
                    }, timeout);
                }
            });

            this.$navMenu.addEventListener('mouseleave', () => {
                this.$navMenu.classList.remove('is--open');
                this.$navObj.nkl.toggle.classList.remove('is--open');
                this.$navObj.skl.toggle.classList.remove('is--open');
                this.$navObj.contact.toggle.classList.remove('is--open');
                this.bodyScrollLock('disable');
            });
        });

        this.$headerNavAnchors.forEach((anchor) => {
            if (anchor.getAttribute('data-nav-toggle') === null) {
                anchor.addEventListener('mouseenter', () => {
                    const waitForAvailable = setInterval(() => {
                        if (this.wait === false) {
                            this.$navObj.nkl.toggle.classList.remove('is--open');
                            this.$navObj.skl.toggle.classList.remove('is--open');
                            this.$navObj.contact.toggle.classList.remove('is--open');
                            this.$navMenu.classList.remove('is--open');

                            this.cancelableTimeout = setTimeout(() => {
                                this.bodyScrollLock('disable');
                                this.menuOpen = false;
                                this.$navObj.skl.menu.classList.remove('is--active');
                                this.$navObj.nkl.menu.classList.remove('is--active');
                                this.$navObj.contact.menu.classList.remove('is--active');
                                this.$activeNav = null;
                            }, 500);
                            clearInterval(waitForAvailable);
                        }
                    }, 50);
                });
            }
        });

        this.$navClose.forEach((closeToggle) => {
            closeToggle.addEventListener('click', () => {
                this.$navMenu.classList.remove('is--open');
                this.$navObj[this.$activeNav].toggle.classList.remove('is--open');
                setTimeout(() => {
                    this.bodyScrollLock('disable');
                    this.$navObj[this.$activeNav].menu.classList.remove('is--active');
                    this.$activeNav = null;
                }, 500);
            });
        });

        if (this.$mobileMenuToggle !== null) {
            this.$mobileMenuToggle.addEventListener('change', () => {
                if (this.$mobileMenuToggle.checked) {
                    this.menuOpen = true;
                    this.bodyScrollLock('enable');
                } else {
                    this.bodyScrollLock('disable');
                    this.menuOpen = false;
                }
            });
        }

        // Start Observation of Header Size (see sizeCheck() )
        // this.resizeObserver.observe(this.$navigation);

        this.checkSize();

        if (this.activeScrollNav) {
            this.checkPosition();
        }
    }

    bodyScrollLock (status) {
        if (status === 'disable') {
            document.body.style.removeProperty('overflow');
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('top');
            document.body.style.removeProperty('width');
            window.scrollTo(0, this.scrollPosition);
        }

        if (status === 'enable') {
            this.scrollPosition = window.pageYOffset;
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.top = '-' + this.scrollPosition + 'px';
            document.body.style.width = '100%';
        }
    }

    checkPosition () {
        window.eventScroller.customFunctions.push(() => {
            if (this.menuOpen === false) {
                const info = window.eventScroller.getScrollInfo();
                const position = info.position;
                const direction = info.direction;

                if (direction === 'up') {
                    if (this.lastScrollPos === 0) {
                        this.lastScrollPos = position;
                    }
                    if (position > this.scrollDistance) {
                        if ((this.lastScrollPos - position) >= (this.scrollDistance / 2)) {
                            if (this.$navigation.classList.contains('is--hidden')) {
                                this.$navigation.classList.add('is--visible');
                                this.$navigation.classList.remove('is--hidden');
                            } else {
                                this.$navigation.classList.add('is--visible');
                            }
                        }
                    } else {
                        if (this.$navigation.classList.contains('is--hidden')) {
                            this.$navigation.classList.add('is--visible');
                            this.$navigation.classList.remove('is--hidden');
                        } else {
                            this.$navigation.classList.add('is--visible');
                        }
                    }
                }

                if (direction === 'down') {
                    if (this.lastScrollPos !== 0) {
                        this.lastScrollPos = 0;
                    }
                    if (position > this.scrollDistance) {
                        if (!this.$navigation.classList.contains('is--fixed')) {
                            this.$navigation.classList.add('is--fixed');

                            if (this.$cartReminder !== null) {
                                if (this.$cartReminder.checked) {
                                    this.$cartReminder.checked = false;
                                }
                            }
                        }
                        if (this.$navigation.classList.contains('is--visible')) {
                            this.$navigation.classList.remove('is--visible');
                            this.$navigation.classList.add('is--hidden');
                        }
                    }
                }

                if (position === 0) {
                    this.$navigation.classList.remove('is--fixed');
                    this.$navigation.classList.remove('is--visible');
                }
            }
        });
    }

    checkSize () {
        document.addEventListener('design_xl', () => {
            if (this.$mobileMenuToggle) {
                if (this.$mobileMenuToggle.checked) {
                    this.$mobileMenuToggle.checked = false;
                    this.bodyScrollLock('disable');
                }
            }

            if (this.$activeNav !== null) {
                this.$navMenu.classList.remove('is--open');
                this.$navObj[this.$activeNav].toggle.classList.remove('is--open');
                this.$navObj[this.$activeNav].menu.classList.remove('is--active');
                this.bodyScrollLock('disable');
                this.$activeNav = null;
            }
        });
    }
}

export { Navigation };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const $nav = $context.querySelector('[data-nav="root"]');

    if ($nav) {
        const $navigation = new Navigation();
        $navigation.initialize();
    }
});
